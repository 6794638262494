v<template>
  <v-dialog
    persistent
    fullscreen
    :value="visible"
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-toolbar color="primary" dark
        >Antrag zur Prüfung eines Vertragsunterbruchs</v-toolbar
      >
      <v-stepper flat v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1"
            >Kundeninformationen</v-stepper-step
          >

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2"
            >Vertrag auswählen</v-stepper-step
          >

          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3">Timestop</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 4" step="4">Abschluss</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card color="transparent" elevation="0">
              <v-row no-gutters v-if="customerInfos.selection">
                <v-col class="pb-0" cols="12">
                  <v-select
                    :disabled="viewOnly"
                    label="Anrede"
                    :items="[
                      { name: 'Herr', value: 'm' },
                      { name: 'Frau', value: 'w' },
                    ]"
                    item-value="value"
                    item-text="name"
                    class="my-0 py-0"
                    v-model="customerInfos.gender"
                  ></v-select>
                </v-col>

                <v-col class="py-0" cols="6">
                  <v-text-field
                    :disabled="viewOnly"
                    label="Vorname"
                    class="my-0 py-0"
                    v-model="customerInfos.firstName"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="6">
                  <v-text-field
                    :disabled="viewOnly"
                    label="Nachname"
                    class="my-0 py-0"
                    v-model="customerInfos.lastName"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="6">
                  <v-text-field
                    :disabled="viewOnly"
                    label="Geburtstag"
                    type="date"
                    class="my-0 py-0"
                    v-model="customerInfos.birthday"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0" cols="6">
                  <v-text-field
                    :disabled="viewOnly"
                    label="Telefon"
                    class="my-0 py-0"
                    v-model="customerInfos.phone"
                  ></v-text-field>
                </v-col>

                <v-col class="py-0" cols="12">
                  <v-text-field
                    :disabled="viewOnly"
                    label="Strasse"
                    class="my-0 py-0"
                    v-model="customerInfos.street"
                  ></v-text-field>
                </v-col>

                <v-col class="pt-0" cols="6">
                  <v-text-field
                    :disabled="viewOnly"
                    type="number"
                    label="Postleitzahl"
                    class="my-0 py-0"
                    v-model="customerInfos.zip"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0" cols="6">
                  <v-text-field
                    :disabled="viewOnly"
                    label="Stadt"
                    class="my-0 py-0"
                    v-model="customerInfos.city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-alert type="info">Bitte überprüfe deine Daten</v-alert>
                </v-col>
              </v-row>
              <v-card-actions class="justify-space-between">
                <v-btn @click="closeForm" text>Abbrechen</v-btn>
                <!---  :disabled=" !customerInfos.selection || !creatorInfos.selection" -->
                <v-btn
                  color="primary"
                  :loading="loadingTACContract"
                  @click="confirmUserInfos()"
                >
                  Weiter
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <!-- 

    Mind 1 Monat
    maximal 6 Monate

    halbjahres Vertrag 1/12
    Jahres Vertrag 1/24

    Rückwirkend erlaubt
    NUr in aktueller Laufzeit

    Preis ist nur abo + zusatzpakete (KEINE CHIPS KEINE EINSCHREIBEGEBÜHR)

    2 Gleichzeitig beantragen ja

    nur 2 Jahre zurück

    Immer der günstigste Preis nehmen

    Während bonusperiode kann ich keinen Timestop erfassen

-->
          <v-stepper-content step="2">
            <v-card
              class="mb-12"
              style="border-radius: 25px"
              color="transparent"
              elevation="0"
            >
              <v-card-title
                >In welcher Laufzeit beginnt dein Timestop?</v-card-title
              >
              <v-card-subtitle>
                Deine Mitgliedschaft bei uns verlängert sich um die Zeitdauer
                deines Timestops.
              </v-card-subtitle>
              <v-row>
                <!-- .slice(-2)-->
                <v-col
                  cols="12"
                  v-for="(contract, index) in reversedContractTimespans"
                  :key="'contract' + index"
                >
                  <v-card
                    class="ma-1"
                    elevation="1"
                    @click="setContractActive(contract)"
                  >
                    <v-card-title>
                      {{ contract.title }}
                      <v-chip
                        class="ml-2"
                        v-if="
                          showPrices &&
                          contract.transactions.length &&
                          contract.transactions
                            .filter((item) => item.type !== 'IDLE_PERIOD')
                            .reduce((total, item) => {
                              return total + Math.abs(item.cost.value);
                            }, 0) > 0
                        "
                      >
                        {{
                          contract.transactions
                            .filter((item) => item.type !== "IDLE_PERIOD")
                            .reduce((total, item) => {
                              return total + Math.abs(item.cost.value);
                            }, 0) + ".-"
                        }}
                      </v-chip>
                      <v-chip
                        class="ml-2"
                        color="primary"
                        v-if="contract.active"
                        >Aktiv</v-chip
                      >
                      <v-chip class="ml-2" color="info" v-if="contract.future"
                        >Zukünftig</v-chip
                      >
                    </v-card-title>
                    <v-card-text>
                      Laufzeit:
                      {{ contract.start + " - " }}
                      <span :class="{ 'primary--text': contract.bonus.length }">
                        {{
                          contract.timeStopBonus.length
                            ? contract.timeStopBonus[
                                contract.timeStopBonus.length - 1
                              ].end
                            : contract.bonus.length
                            ? contract.bonus[contract.bonus.length - 1].end
                            : contract.end
                        }}
                      </span>
                      <br />
                      <p
                        class="mb-1 font-weight-bold"
                        v-if="contract.bonus && contract.bonus.length"
                      >
                        Bonuslaufzeiten von:
                      </p>
                      <v-chip
                        v-for="(bonus, index) in contract.bonus"
                        :key="'bonus' + index"
                      >
                        {{ bonus.start + " - " + bonus.end }}
                      </v-chip>
                      <p
                        class="mb-1 font-weight-bold"
                        v-if="
                          contract.idlePeriods && contract.idlePeriods.length
                        "
                      >
                        existierende Timestops von:
                      </p>
                      <v-chip
                        class="mb-1"
                        v-for="(period, index) in contract.idlePeriods"
                        :key="'period' + index"
                      >
                        {{
                          period.start +
                          " - " +
                          period.end +
                          " " +
                          (period.transactions && period.transactions.length
                            ? period.transactions.reduce((total, item) => {
                                return total + Math.abs(item.cost.value);
                              }, 0) + ".-"
                            : "")
                        }}
                      </v-chip>
                      <p
                        class="mb-1 font-weight-bold"
                        v-if="
                          contract.timeStopBonus &&
                          contract.timeStopBonus.length
                        "
                      >
                        verlängerung aufgrund Timestop
                      </p>
                      <v-chip
                        class="mb-1"
                        v-for="(period, index) in contract.timeStopBonus"
                        :key="'timestop' + index"
                      >
                        {{ period.start + " - " + period.end }}
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-card-actions class="justify-space-between">
                <v-btn text @click="e1--">Zurück</v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content class="fill-height" step="3">
            <v-card
              v-if="selectedContract"
              class="mb-12 fill-height"
              color="transparent"
              elevation="0"
            >
              <v-row>
                <v-col cols="12">
                  <v-alert type="info">
                    Ausgewählte Laufzeit
                    <br />
                    {{ selectedContract.start + " - " }}
                    {{
                      selectedContract.timeStopBonus.length
                        ? selectedContract.timeStopBonus[
                            selectedContract.timeStopBonus.length - 1
                          ].end
                        : selectedContract.bonus.length
                        ? selectedContract.bonus[
                            selectedContract.bonus.length - 1
                          ].end
                        : selectedContract.end
                    }}
                    <br />
                    mind. Dauer 1 Monat
                    <br />
                    max. Dauer 6 Monate
                    <br />
                    <span v-if="selectedContract.transactions && false">
                      Gebühren mit Arztzeugnis:
                      <br />
                      {{ 1 + " / " + dividePriceBy }} vom Abopreis
                      <br />
                      <br />
                      Gebühren ohne Arztzeugnis
                      <br />
                      {{ 1 + " / " + dividePriceBy }} vom Abopreis * Monate
                      <!-- 

                                             {{
                                                 Math.round(
                                                     (selectedContract.transaction.cost.value / dividePriceBy) * 100
                                                     ) / 100
                                                    }}
                                                -->
                    </span>
                  </v-alert>
                </v-col>
                <v-col class="pb-0" cols="12">
                  <v-menu
                    v-model="startDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timestopInfos.start"
                        label="Start"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="de-CH"
                      :min="
                        $moment(selectedContract.start, 'DD.MM.YYYY').format()
                      "
                      :max="calcStartPickerMaxDate"
                      v-model="timestopInfos.start"
                      @change="timestopInfos.end = null"
                      @input="startDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="12">
                  <v-menu
                    v-model="endDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="timestopInfos.end"
                        label="Ende"
                        prepend-icon="mdi-calendar"
                        readonly
                        :disabled="!timestopInfos.start"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="de-CH"
                      :min="
                        $moment(timestopInfos.start, 'YYYY-MM-DD')
                          .add('months', 1)
                          .subtract('days', 1)
                          .format()
                      "
                      :max="calcEndPickerMaxDate"
                      v-model="timestopInfos.end"
                      @input="endDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  v-if="timestopInfos.start && timestopInfos.end"
                >
                  <v-alert type="info">
                    Dauer des Unterbruchs:
                    {{
                      $moment(timestopInfos.end, "YYYY-MM-DD").diff(
                        $moment(timestopInfos.start, "YYYY-MM-DD"),
                        "days"
                      ) + 1
                    }}
                    Tage
                    <div v-if="showPrices">
                      <br />
                      Geschätzte Gebühren:
                      <br />
                      Mit Attest:
                      {{ getPriceWithAttest }} CHF
                      <br />
                      Ohne Attest:
                      {{ getPriceWithoutAttest }} CHF
                    </div>
                  </v-alert>
                </v-col>
                <v-col v-if="tacReasons" class="py-0" cols="12">
                  <v-select
                    mandatory
                    :items="
                      tacReasons.Reasons.Reason.map((reason) => {
                        return {
                          text: reason.ReasonCode,
                          value: reason.ReasonID.UniqueID,
                        };
                      })
                    "
                    @change="changeTimestopReason"
                    v-model="timestopInfos.tacReason"
                    label="Grund des Unterbruchs"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="withAttest">
                <v-col cols="12">
                  <v-row class="upload-section">
                    <v-col cols="12" class="mb-0">
                      <v-alert type="info">
                        Lade bitte eine Bescheinigung hoch, um deinen Antrag auf
                        Unterbrechung der Mitgliedschaft zu vervollständigen.
                        Die Informationen in deinem Anhang müssen mit dem
                        angegebenen Zeitraum übereinstimmen.
                      </v-alert>
                      <v-file-input
                        multiple
                        @change="uploadImagesTimestops"
                        label="Bilder und Dokumente auswählen"
                      ></v-file-input>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      timestopInfos.gallery && timestopInfos.gallery.length > 0
                    "
                  >
                    <v-col cols="12">
                      <v-window v-model="activeIndexTimestops">
                        <v-window-item
                          v-for="(image, index) in timestopInfos.gallery"
                          :key="`card-${index}`"
                        >
                          <v-card color="transparent" height="200">
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center"
                            >
                              <v-col align="center" cols="12">
                                <span class="font-weight-bold">
                                  {{ image.title }}
                                </span>
                                <v-icon
                                  v-if="
                                    image.title.includes('.pdf') ||
                                    image.title.includes('.PDF')
                                  "
                                  x-large
                                >
                                  mdi-file-pdf-box
                                </v-icon>
                                <v-img
                                  contain
                                  class="mx-auto"
                                  v-else-if="image.dataUrl"
                                  height="150px"
                                  max-width="200px"
                                  :src="image.dataUrl"
                                  alt="Uploaded Image"
                                ></v-img>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-window-item>
                      </v-window>
                      <v-card-actions class="justify-center">
                        <v-item-group
                          v-model="activeIndexTimestops"
                          class="text-center"
                          mandatory
                        >
                          <v-item
                            v-for="n in timestopInfos.gallery.length"
                            :key="`btn-${n}`"
                            v-slot="{ active, toggle }"
                          >
                            <v-btn :input-value="active" icon @click="toggle">
                              <v-icon>mdi-record</v-icon>
                            </v-btn>
                          </v-item>
                        </v-item-group>
                      </v-card-actions>
                    </v-col>
                  </v-row>

                  <!-- Gallery Section -->
                  <v-row v-if="false" class="gallery-section">
                    <v-col cols="12">
                      <div
                        ref="scrollContainer"
                        @scroll="updateActiveIndexPackages"
                        style="
                          overflow-x: scroll;
                          white-space: nowrap;
                          scroll-snap-type: x mandatory;
                        "
                      >
                        <v-card
                          elevation="0"
                          transparent
                          v-for="(image, index) in timestopInfos.gallery"
                          :key="index"
                          ref="cards"
                          style="
                            display: inline-block;
                            scroll-snap-align: center;
                            width: 100%;
                          "
                        >
                          <v-img
                            contain
                            max-height="200px"
                            :src="'/api/' + image.src"
                            alt="Uploaded Image"
                          ></v-img>

                          <v-card-actions>
                            <v-btn
                              class="mx-auto"
                              @click="deleteImageTimestops(index)"
                              color="error"
                            >
                              Löschen
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-col>
                    <v-col class="mb-5" cols="12" style="text-align: center">
                      <span
                        v-for="(image, index) in timestopInfos.gallery"
                        :key="'dot-' + index"
                        @click="scrollToIndexPackages(index)"
                        :class="[
                          'dot',
                          { active: activeIndexPackages === index },
                        ]"
                      ></span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-card-actions class="justify-space-between">
                <v-btn text @click="e1--">Zurück</v-btn>

                <v-btn
                  :loading="checkingCheckins"
                  color="primary"
                  :disabled="
                    !timestopInfos.start ||
                    !timestopInfos.end ||
                    (withAttest && !timestopInfos.gallery.length) ||
                    !timestopInfos.tacReason
                  "
                  @click="checkCheckins()"
                >
                  weiter
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card class="mb-12" color="transparent" elevation="0">
              <v-card-title>Bedingungen</v-card-title>
              <v-expansion-panels class="mt-1">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >1. Interne Prüfung</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    Mit einer Genehmigung der Geschäftsleitung der well come FIT
                    AG kann die Mitgliedschaft unterbrochen werden. Der Antrag
                    kann jederzeit abgelehnt werden.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >2. Dauer des Unterbruchs</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    Der Unterbruch muss mindestens einen Monat betragen und darf
                    sechs Monate nicht überschreiten.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >3. Unterlagen</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    Dem Antrag sind Unterlagen zur Prüfung beizulegen. Eine
                    einmalige Gebühr ist bei Vorlage eines ärztlichen Attests,
                    eines Marschbefehls oder eines Nachweises für einen
                    Sprachaufenthalt zu entrichten.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    4. Zahlungspflicht während des Unterbruchs
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Ein Unterbruch befreit nicht von der Zahlungspflicht sowie
                    der automatischen Abonnementerneuerung und wird nach
                    Bezahlung der Unterbruchgebühr der aktuellen Laufzeit
                    angerechnet.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >5. Höhe der anfallenden Gebühr</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    Die Gebühr beträgt beim Jahresabonnement 1/24 und beim
                    Halbjahresabonnement 1/12 des Mitgliederbeitrages pro Monat
                    Unterbruch.
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    >6. Maximal zwei Unterbrüche</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    Während der Abonnementsdauer sind maximal zwei Unterbrüche
                    möglich.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-card-text
                >Mit meiner Unterschrift akzeptiere ich die
                Bedingungen.</v-card-text
              >

              <signature-pad
                class="mx-auto"
                :signature-data="customerInfos.signature"
                @startDrawing="signature = true"
                @resetDrawing="resetSignature"
                @saveSignature="saveSignature"
                ref="signatureConditions"
              ></signature-pad>
            </v-card>

            <v-btn text @click="e1--">Zurück</v-btn>
            <v-btn color="primary" @click="saveEntry()">Speichern</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import FormWizard from "../../../components/vueStepWizard/FormWizard.vue";
import userSearch from "../../../components/userSearch.vue";
import signaturePad from "@/components/signaturePad/signaturePad.vue";
import api from "@/api";
import { mapState } from "vuex";
export default {
  components: { FormWizard, userSearch, signaturePad },
  data() {
    return {
      groupedWintiAbos: [
        [
          "Erneuerung Erwachsene ab 18 Jahre",
          "Erneuerung Erwachsene ab 18 Jahre 2 Raten",
          "Erwachsene ab 18 Jahren",
          "Erwachsene ab 18 Jahren 2 Raten",
          "Erwachsene ab 18 Jahren 12 Raten",
          "Erwachsene ab 18 Jahren Geschäfte 1 Jahr",
          "Erwachsene ab 18 Jahren Geschäfte12 Raten",
          "Erneuerung Erwachsene 1 Jahr",
          "Erneuerung Erwachsene ab 18 Jahre 12 Raten",
        ],
        [
          "3 Jahres Abonnement",
          "3 Jahres Abonnement CHF 1950.00",
          "3 Jahres Abonnement CHF 1800.00",
        ],
        [
          "Erwachsene ab 18 Jahren 1/2 Jahr",
          "Erwachsene 1/2 Jahr 2 Raten",
          "Erwachsene 1/2 Jahr 6 Raten",
        ],
        ["MTT 3 Monate", "MIT 3 Monate"],
        ["AHV/Studenten/Lernende 1/2 Jahr 6 Raten"],
        [
          "AHV/Studenten/Lernende 12 Raten",
          "Erneuerung AHV/Studenten/Lernende 1 Jahr",
          "Erneuerung AHV/Studenten/Lernende 2 Raten",
          "AHV/Studenten/Lernende 1 Jahr",
          "AHV/Studenten/Lernende 1 Jahr 2 Raten",
          "AHV/Studenten/Lernende 12 Raten",
          "Kombi Card AHV / IV 1 Jahr, 12 Raten",
          "Erneuerung AHV/Studenten/Lehrlinge 1 Jahr",
          "AHV/Studenten/Lernende 1/2 Jahr",
          "AHV/Studenten/Lernende 1/2 Jahr 2 Raten",
        ],
      ],
      profileID: null,
      fixTermTimesMissingBefore: [],
      fixTermTimesMissingAfter: [],
      dividePriceBy: false,
      activeIndexTimestops: 0,
      signature: false,
      timestopText: "",
      withAttest: false,
      e1: 1,
      timestopInfos: {
        gallery: [],
        start: null,
        end: null,
        tacReason: null,
        duration: null,
        durationSelection: null,
        fee: null,
        timeAndPlace: "",
      },
      customerInfos: {
        selection: null,
        signature: null,
      },
      existingTimestops: [],
      selectedFile: null,
      uploadStatus: "",
      imageSources: {},
      timestopFormId: null,
      loadingTACContract: false,
      contractEndDate: null,
      contractStartDate: null,
      startDatePicker: false,
      endDatePicker: false,
      selectedContract: null,
      contractInformations: null,
      allTransactions: [],
      checkins: [],
      checkingCheckins: false,
      allIdlePeriods: [],
      tacReasons: [],
    };
  },
  async created() {
    let customer = await api.getUserForManagerByEmail(this.tacEmail);

    let customerEntry = {
      selection: customer._id,
      gender: customer.geschlecht,
      firstName: customer.vorname,
      lastName: customer.nachname,
      email: customer.email,
      phone: customer.telefon,
      birthday: customer.geburtstag,
      street: customer.adresse_1,
      city: customer.stadt,
      zip: customer.plz,
      signature: null,
    };
    this.timestopInfos.timeAndPlace = customer.stadt;
    //this.resetLegalRepresentative()

    this.customerInfos = customerEntry;
  },
  methods: {
    areInSameCategory(string1, string2) {
      console.log(string1, string2);
      for (let category of this.groupedWintiAbos) {
        if (category.includes(string1) && category.includes(string2)) {
          return true;
        }
      }
      return false;
    },
    changeTimestopReason(selectedReason) {
      let selectedVal = this.tacReasons.Reasons.Reason.find(
        (reason) => reason.ReasonID.UniqueID === selectedReason
      ).ReasonCode;
      this.withAttest =
        selectedVal.toLowerCase().includes("arzt") ||
        selectedVal.toLowerCase().includes("schwanger") ||
        selectedVal.toLowerCase().includes("milit") ||
        selectedVal.toLowerCase().includes("sprachauf");
    },
    setContractActive(contract) {
      if (contract.idlePeriods.length >= 2) {
        return alert(
          "Du hast in dieser Laufzeit bereits die maximale Anzahl von 2 Timestops."
        );
      }
      this.selectedContract = contract;
      this.timestopInfos = {
        gallery: [],
        start: null,
        end: null,
        tacReason: null,
        duration: null,
        durationSelection: null,
        fee: null,
        timeAndPlace: "",
      };
      this.withAttest = false;
      this.e1 = 3;
    },
    async checkCheckins() {
      this.checkingCheckins = true;
      this.checkins = await api.fetchCheckinsPerTAC({
        MemberInfo: {
          MemberNumber: this.contractInformations.MemberNumber,
        },
        TimeSpan: {
          number: 0,
          Start: this.$moment(this.timestopInfos.start, "YYYY-MM-DD")
            .add("days", 1)
            .format(),
          End: this.$moment(this.timestopInfos.end, "YYYY-MM-DD").format(),
        },
      });
      if (this.checkins && this.checkins.length) {
        alert(
          "Du kannst keinen Timestop für den Gewünschten Zeitraum erstellen, da du dich am " +
            this.$moment(this.checkins[0]).format("DD.MM.YYYY") +
            " eingecheckt hast.\nAnzahl Checkins im gewählten Zeitraum: " +
            this.checkins.length
        );
      } else {
        for (let idlePeriod of this.allIdlePeriods) {
          if (
            this.$moment(this.timestopInfos.start, "YYYY-MM-DD").isBetween(
              idlePeriod.start,
              idlePeriod.end,
              "day",
              "[]"
            ) ||
            this.$moment(this.timestopInfos.end, "YYYY-MM-DD").isBetween(
              idlePeriod.start,
              idlePeriod.end,
              "day",
              "[]"
            )
          ) {
            this.checkingCheckins = false;
            return alert(
              "Du hast bereits einen Timestop in deiner ausgewählten Zeitspanne."
            );
          }
        }
        this.e1 = 4;
      }
      this.checkingCheckins = false;
    },
    async confirmUserInfos() {
      if (
        this.customerInfos.birthday === "--" ||
        this.customerInfos.gender === "a" ||
        !this.customerInfos.selection ||
        !this.customerInfos.gender ||
        !this.customerInfos.firstName ||
        !this.customerInfos.lastName ||
        !this.customerInfos.phone ||
        !this.customerInfos.birthday ||
        !this.customerInfos.street ||
        !this.customerInfos.city ||
        !this.customerInfos.zip
      ) {
        return this.$toast.error("Bitte fülle alle Felder aus");
      }

      if (confirm("Ich bestätige, dass meine Daten korrekt sind")) {
        this.loadingTACContract = true;
        let error = false;
        let contract = null;
        let allExistingContracts = null;
        let transactions = null;
        this.fixTermTimesMissingBefore = [];
        this.fixTermTimesMissingAfter = [];
        this.allTransactions = [];
        //let testmail = await api.tacPayTestEmail()
        //let testAccs = 'leyla, joya, rosa, julia, vanessa, mari, alena, desireh, raphaela'
        try {
          contract = await api.fetchTACMembershipByEmailAdminTESTING(
            this.tacEmail
          );

          allExistingContracts = await api.fetchTACAllMembershipsByEmail(
            this.tacEmail
          );
          if (allExistingContracts?.MemberDetails.MemberDetail) {
            let vipImport =
              allExistingContracts.MemberDetails.MemberDetail.find((detail) => {
                return (
                  detail.MemberCategoryName === "VIP Import" &&
                  detail.ContractID.UniqueID[0].value !==
                    contract.MemberDetail.ContractID.UniqueID[0].value
                );
              });
            if (vipImport) {
              this.fixTermTimesMissingBefore =
                this.fixTermTimesMissingBefore.concat(
                  vipImport.ContractTermTimes.ContractTermTime
                );
            }

            let sameContractTermTimes =
              allExistingContracts.MemberDetails.MemberDetail.filter(
                (existingContract) =>
                  existingContract.MemberCategoryName ===
                    contract.MemberDetail.MemberCategoryName ||
                  this.areInSameCategory(
                    existingContract.MemberCategoryName,
                    contract.MemberDetail.MemberCategoryName
                  )
              );
            if (sameContractTermTimes?.length) {
              for (let sameContract of sameContractTermTimes) {
                if (
                  contract.MemberDetail.ContractTermTimes.ContractTermTime.find(
                    (contTime) =>
                      contTime.TimeSpan.Start ===
                      sameContract.ContractTermTimes.ContractTermTime[0]
                        .TimeSpan.Start
                  )
                ) {
                  console.log("ich continue do emol");
                  continue;
                }
                if (
                  contract.MemberDetail.ContractID.UniqueID[0].value ===
                  sameContract.ContractID.UniqueID[0].value
                ) {
                  console.log("found");
                  continue;
                }

                if (
                  this.$moment(
                    sameContract.ContractTermTimes.ContractTermTime[0].TimeSpan
                      .Start,
                    "YYYY-MM-DD"
                  ).isBefore(
                    this.$moment(
                      contract.MemberDetail.ContractTermTimes
                        .ContractTermTime[0].TimeSpan.Start,
                      "YYYY-MM-DD"
                    )
                  )
                ) {
                  this.fixTermTimesMissingBefore =
                    this.fixTermTimesMissingBefore.concat(
                      sameContract.ContractTermTimes.ContractTermTime
                    );
                } else {
                  this.fixTermTimesMissingAfter =
                    sameContract.ContractTermTimes.ContractTermTime;
                }
              }
            }
          }
        } catch (e) {
          error = true;
          console.log(e);
          alert(
            "Wir konnten leider keine Mitgliedschaft für dich finden. Es kann sein, dass wir deine Mitgliedschaft mit einer anderen E-Mail Adresse hinterlegt haben. Bitte wende dich an das Personal um dies zu prüfen."
          );
          this.$router.push("/dashboard/timestop");
        }

        try {
          if (!error) {
            transactions = await api.fetchAccountTransactionsForIDAdminTESTING(
              contract.MemberDetail.Accounts.Account.find(
                (acc) => acc.type === "MEMBERSHIP"
              ).AccountID.UniqueID[0].value,
              this.$moment("2000-01-01", "YYYY-MM-DD").format(),
              this.$moment().add(4, "years").format()
            );
          }
        } catch (e) {
          await alert(
            "Wir konnten leider keine Zahlungen für deine Mitgliedschaft finden. Falls dieses Problem trotz Zahlung auftritt, wende dich bitte an das Personal. "
          );
          this.$router.push("/dashboard/timestop");
        }

        try {
          let allTACReasons = await api.getTACReasons(
            contract.MemberDetail.PropertyID.UniqueID[0].value
          );

          allTACReasons.Reasons.Reason = allTACReasons.Reasons.Reason.filter(
            (reason) => reason.ValidTimes
          );
          this.tacReasons = allTACReasons;
          /*if (this.tacReasons) {
                        this.timestopInfos.tacReason = this.tacReasons.Reasons.Reason[0].ReasonID.UniqueID
                    }*/
        } catch (e) {
          this.$toast.error("Fehler beim Laden der Timestop Gründe.");
        }

        transactions.AccountTransactions.AccountTransactions.forEach(
          (transaction) => {
            if (transaction.Type === "IDLE_PERIOD") {
              this.allTransactions.push({
                start: transaction.IdlePeriodTimeRange.Start,
                end: transaction.IdlePeriodTimeRange.End,
                cost: transaction.Value,
                state: transaction.State,
                type: transaction.Type,
                mainType: transaction.MainType,
                det: transaction.AccountTransactionDetails,
              });
            } else if (
              transaction.AccountTransactionDetails.AccountTransactionDetail[0]
                .TimeRange
            ) {
              if (
                (transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                  (det) => det.Description === "Einschreibgebühr"
                ) ||
                  transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                    (det) => det.Description === "Aktivierungs-Tarif"
                  )) &&
                !transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                  (det) =>
                    det.Description === "Erlass Fr. 99.00 Einschreibegebühr"
                ) &&
                !transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                  (det) =>
                    det.Description === "Erlass Fr. 99.00 Aktivierungs-Tarif"
                )
              ) {
                if (transaction.Value.value) {
                  transaction.Value.value -= 99;
                } else {
                  transaction.Value -= 99;
                }
              } else if (
                transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                  (det) => det.Description.includes("abatt")
                )
              ) {
                transaction.Value.value =
                  parseInt(transaction.Value.value) +
                  Math.abs(
                    parseInt(
                      transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                        (det) => det.Description.includes("abatt")
                      ).Value.value
                    )
                  );
              }
              if (
                transaction.AccountTransactionDetails.AccountTransactionDetail.find(
                  (det) => det.Description === "3 Jahres Abonnement"
                )
              ) {
                if (transaction.Value.value) {
                  transaction.Value.value =
                    Number(transaction.Value.value) + 570;
                } else {
                  transaction.Value = Number(transaction.Value) + 570;
                }
              }
              let timeRange =
                transaction.AccountTransactionDetails
                  .AccountTransactionDetail[0].TimeRange;
              this.allTransactions.push({
                start: timeRange.Start,
                end: timeRange.End,
                cost: transaction.Value,
                state: transaction.State,
                type: transaction.Type,
                mainType: transaction.MainType,
              });
            }
          }
        );

        let price = 0;
        this.contractInformations = JSON.parse(
          JSON.stringify(contract.MemberDetail)
        );
        this.profileID = JSON.parse(
          JSON.stringify(
            contract.MemberDetail.Profile.Profile.Profile.IDs.UniqueID[0].value
          )
        );
        let timeSpans =
          contract.MemberDetail.ContractTermTimes.ContractTermTime;
        let firstTimeSpan = timeSpans.find(
          (timespan) => timespan.Type === "DEFAULT"
        ).TimeSpan;
        this.dividePriceBy =
          this.$moment(firstTimeSpan.End).diff(
            this.$moment(firstTimeSpan.End),
            "months"
          ) > 6
            ? 12
            : 24;
        let endTimeSpan =
          contract.MemberDetail.ContractTermTimes.ContractTermTime[
            timeSpans.length - 1
          ];
        this.contractEndDate = this.$moment(
          endTimeSpan.TimeSpan.End,
          "YYYY-MM-DD"
        ).format("DD.MM.YYYY");

        let contractTermTimeCounts =
          contract.MemberDetail.ContractTermTimes.ContractTermTime.length;
        this.contractStartDate = this.$moment(
          timeSpans.reverse().find((ts) => ts.Type === "DEFAULT").TimeSpan
            .Start,
          "YYYY-MM-DD"
        ).format("DD.MM.YYYY");
        this.loadingTACContract = false;
        this.e1 = 2;
      }
    },
    deleteImageTimestops(index) {
      this.timestopInfos.gallery.splice(index, 1);
      this.$forceUpdate();
    },

    uploadImagesTimestops(files) {
      for (let file of files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const formData = new FormData();
          formData.append("file", file);

          api
            .uploadTimestopImage(formData)
            .then((response) => {
              const uploadedImageName = response.name;
              if (!this.timestopInfos.gallery) this.timestopInfos.gallery = [];
              this.timestopInfos.gallery.push({
                title: file.name,
                src: uploadedImageName,
                dataUrl: e.target.result,
              });

              this.$forceUpdate();
            })
            .catch((error) => {
              this.$toast.error(
                "Fehler beim hinzufügen deiner Datei. Achte darauf, dass nur gängige Bildformate und PDFs erlaubt sind"
              );
              console.log("Upload failed", error);
            });
        };
        reader.readAsDataURL(file);
      }
    },

    resetSignature() {
      this.signature = false;
      this.customerInfos.signature = null;
    },
    daysInTimeSpan(timeSpan) {
      const start = this.$moment(timeSpan.TimeSpan.Start, "YYYY-MM-DD");
      const end = this.$moment(timeSpan.TimeSpan.End, "YYYY-MM-DD");
      return end.diff(start, "days");
    },
    async saveEntry() {
      let propStudio = this.storeStudios.find(
        (stud) =>
          stud.tacPropertyId ===
          this.contractInformations.PropertyID.UniqueID[0].value
      );
      if (!propStudio || !propStudio.tacResortId) {
        let apiStudios = await api
          .fetchStudios()
          .catch((e) => (this.error = true));
        propStudio = apiStudios.find(
          (stud) =>
            stud.tacPropertyId ===
            this.contractInformations.PropertyID.UniqueID[0].value
        );
      }
      let data = {
        checkOnly: false,
        ContractID: this.selectedContract.ContractID,
        TimeSpan: {
          Start: this.timestopInfos.start,
          End: this.timestopInfos.end,
        },
        Duration: Number(
          this.$moment(this.timestopInfos.end, "YYYY-MM-DD").diff(
            this.$moment(this.timestopInfos.start, "YYYY-MM-DD"),
            "days"
          ) + 1
        ),
        Comment: "Kommentar",
        TimeUnit: "DAY",
        ReasonId: this.timestopInfos.tacReason,
        Reason: this.tacReasons.Reasons.Reason.find(
          (reason) => reason.ReasonID.UniqueID === this.timestopInfos.tacReason
        ).ReasonCode,

        ResortID: propStudio.tacResortId,
        IdlePeriodFee: {
          currencyCode: "CHF",
          value: Number(this.getPriceWithoutAttest),
        },
        IdlePeriodType: "EXTEND_USAGETIME",
      };
      // let test = await api.createTACTimestop(data)
      this.$refs.signatureConditions.saveSignature();
      let entry = {
        withAttest: this.withAttest,
        studio: propStudio._id,
        customerInfos: this.customerInfos,
        timestopInfos: this.timestopInfos,
        profileID: this.profileID,
        //timestopFormId: this.timestopFormId,
        status: "open",
        tacData: data,
        fee: {
          withAttest: this.getPriceWithAttest,
          withoutAttest: this.getPriceWithoutAttest,
        },
      };

      try {
        await api.saveTimestopEntry(entry);
        this.$toast.success("Antrag erfolgreich gestellt");
        this.$emit("onSuccess");
      } catch (e) {
        console.log(e);
        this.$toast.error("Fehler beim abspeichern des Antrags");
      }
    },

    saveSignature(e) {
      this.customerInfos.signature = e;
    },
    closeForm() {
      this.$emit("onClose");
    },
    bonusCalculatedFromMultipleTimespans(allTimeSpans, timeSpanDays) {
      const totalDays = allTimeSpans.reduce((total, timeSpan) => {
        return total + this.daysInTimeSpan(timeSpan);
      }, 0);

      return (
        Number(totalDays) === Number(timeSpanDays) ||
        Number(totalDays) - 1 === Number(timeSpanDays) ||
        Number(totalDays) + 1 === Number(timeSpanDays)
      );
    },
  },
  props: {
    showPrices: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },

    newEntry: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    tacEmail: {},
  },
  computed: {
    ...mapState("studioStore", ["storeStudios"]),
    reversedContractTimespans() {
      let sliceCount = this.contractTimespans.find((ts) => ts.future) ? 3 : 2;

      return this.contractTimespans.slice(-sliceCount).reverse();
    },
    getPriceWithAttest() {
      let priceForAbo = this.selectedContract.transactions.reduce(
        (total, item) => {
          return total + Math.abs(item.cost.value);
        },
        0
      );

      if (!priceForAbo) {
        priceForAbo = 690;
      }
      let aboDuration =
        this.$moment(this.selectedContract.end, "DD.MM.YYYY").diff(
          this.$moment(this.selectedContract.start, "DD.MM.YYYY"),
          "days"
        ) + 1;
      let divideBy = this.dividePriceBy / 12;

      let oneMonthFromStart = this.$moment(
        this.timestopInfos.start,
        "YYYY-MM-DD"
      )
        .add("months", 1)
        .diff(this.$moment(this.timestopInfos.start, "YYYY-MM-DD"), "days");

      let withAttest =
        Math.round(
          (((priceForAbo / aboDuration) * oneMonthFromStart) / divideBy) * 20
        ) / 20;
      return this.getPriceWithoutAttest < withAttest
        ? this.getPriceWithoutAttest
        : withAttest;
    },
    getPriceWithoutAttest() {
      let price = this.selectedContract.transactions.reduce((total, item) => {
        return total + Math.abs(item.cost.value);
      }, 0);

      if (!price) price = 690;

      let divideBy = this.dividePriceBy / 12;

      let aboInYears = this.$moment(
        this.selectedContract.end,
        "DD.MM.YYYY"
      ).diff(
        this.$moment(this.selectedContract.start, "DD.MM.YYYY"),
        "years",
        true
      );

      let aboDuration =
        this.$moment(this.selectedContract.end, "DD.MM.YYYY").diff(
          this.$moment(this.selectedContract.start, "DD.MM.YYYY"),
          "days"
        ) + 1;

      let timeStopDuration =
        this.$moment(this.timestopInfos.end, "YYYY-MM-DD").diff(
          this.$moment(this.timestopInfos.start, "YYYY-MM-DD"),
          "days"
        ) + 1;
      return (
        Math.round(
          (((price / aboDuration) * timeStopDuration) / divideBy) * 20
        ) / 20
      );
    },
    latestPossibleDate() {
      let activeContract = this.contractTimespans.find((cont) => cont.active);
      if (this.contractTimespans.find((cont) => cont.future)) {
        activeContract = this.contractTimespans.find((cont) => cont.future);
      }
      let latestDate = null;
      if (activeContract.timeStopBonus.length) {
        latestDate =
          activeContract.timeStopBonus[activeContract.timeStopBonus.length - 1]
            .end;
      } else if (activeContract.bonus.length) {
        latestDate = activeContract.bonus[activeContract.bonus.length - 1].end;
      } else if (latestDate === null) {
        latestDate = activeContract.end;
      }

      return this.$moment(latestDate, "DD.MM.YYYY");
    },
    calcEndPickerMaxDate() {
      let calculatedMax = this.$moment(
        this.timestopInfos.start,
        "YYYY-MM-DD"
      ).add("months", 6);
      if (calculatedMax.isAfter(this.latestPossibleDate)) {
        calculatedMax = this.latestPossibleDate;
      }
      return calculatedMax.format();
    },
    calcStartPickerMaxDate() {
      let latestPossible = this.$moment(this.latestPossibleDate);
      let calculatedMax = this.$moment(
        this.selectedContract.end,
        "DD.MM.YYYY"
      ).subtract("months", 1);

      if (this.selectedContract.timeStopBonus.length) {
        calculatedMax = this.$moment(
          this.selectedContract.timeStopBonus[
            this.selectedContract.timeStopBonus.length - 1
          ].end,
          "DD.MM.YYYY"
        ).subtract("months", 1);
      } else if (this.selectedContract.bonus.length) {
        calculatedMax = this.$moment(
          this.selectedContract.bonus[this.selectedContract.bonus.length - 1]
            .end,
          "DD.MM.YYYY"
        ).subtract("months", 1);
      }

      if (
        calculatedMax.isBefore(
          this.$moment(latestPossible).subtract("months", 1)
        )
      ) {
        calculatedMax = latestPossible.subtract("months", 1);
      }
      return calculatedMax.format();
    },

    contractTimespans() {
      if (!this.contractInformations) return [];
      let timeSpans =
        this.contractInformations.ContractTermTimes.ContractTermTime;
      // console.log('before', this.fixTermTimesMissingBefore)
      //console.log('after', this.fixTermTimesMissingAfter)
      timeSpans = this.fixTermTimesMissingBefore.concat(
        timeSpans,
        this.fixTermTimesMissingAfter
      );
      //  console.log('am schluss gits: ', timeSpans)
      let activeContractDate = JSON.parse(JSON.stringify(timeSpans))
        .reverse()
        .find(
          (ts) =>
            ts.Type === "DEFAULT" &&
            !this.$moment(ts.TimeSpan.Start, "YYYY-MM-DD").isAfter(
              this.$moment(),
              "day"
            )
        ).TimeSpan.Start;

      let contracts = [];
      let newContract = null;
      let idlePeriodTimeSpans = timeSpans.filter(
        (timespan) => timespan.Type === "IDLEPERIOD"
      );
      timeSpans.forEach((timespan) => {
        let filteredTimeSpans = JSON.parse(JSON.stringify(idlePeriodTimeSpans));
        if (newContract) {
          filteredTimeSpans = filteredTimeSpans.filter((filterTimeSpan) => {
            console.log(
              filterTimeSpan.TimeSpan.Start,
              filterTimeSpan.TimeSpan.End,
              newContract.start,
              newContract.end,
              this.$moment(filterTimeSpan.TimeSpan.Start).isSameOrAfter(
                this.$moment(newContract.start, "DD.MM.YYYY")
              )
            );
            return this.$moment(filterTimeSpan.TimeSpan.Start).isSameOrAfter(
              this.$moment(newContract.start, "DD.MM.YYYY")
            );
          });
        }
        if (timespan.Type === "DEFAULT") {
          if (
            newContract &&
            idlePeriodTimeSpans.length &&
            (idlePeriodTimeSpans.find((idleTimeSpan) => {
              return (
                this.daysInTimeSpan(idleTimeSpan) ===
                this.daysInTimeSpan(timespan)
              );
            }) ||
              this.bonusCalculatedFromMultipleTimespans(
                filteredTimeSpans,
                this.daysInTimeSpan(timespan)
              ))
          ) {
            newContract.timeStopBonus.push({
              start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ),
              end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ),
            });
          } else {
            if (newContract !== null) contracts.push(newContract);
            newContract = {
              ContractID: timespan.ContractID.UniqueID[0].value,
              title: this.contractInformations.MemberCategoryName,
              start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ),
              end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              ),
              bonus: [],
              timeStopBonus: [],
              idlePeriods: [],
              transactions:
                this.allTransactions.filter(
                  (transaction) =>
                    this.$moment(transaction.start, "YYYY-MM-DD").isSameOrAfter(
                      this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD")
                    ) &&
                    this.$moment(transaction.end, "YYYY-MM-DD").isSameOrBefore(
                      this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD")
                    ) &&
                    transaction.type !== "IDLE_PERIOD"
                ) || [],
              active: activeContractDate === timespan.TimeSpan.Start,
              future: this.$moment(
                timespan.TimeSpan.Start,
                "YYYY-MM-DD"
              ).isAfter(this.$moment()),
            };
          }
        }
        if (timespan.Type === "BONUSPERIOD") {
          newContract.bonus.push({
            start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format(
              "DD.MM.YYYY"
            ),
            end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format(
              "DD.MM.YYYY"
            ),
          });
        }
      });
      contracts.push(newContract);
      idlePeriodTimeSpans.forEach((timespan) => {
        let foundContract = contracts.find((cont) => {
          let contEndDate = cont.timeStopBonus.length
            ? cont.timeStopBonus[cont.timeStopBonus.length - 1].end
            : cont.bonus.length
            ? cont.bonus[cont.bonus.length - 1].end
            : cont.end;
          return this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").isBetween(
            this.$moment(cont.start, "DD.MM.YYYY"),
            this.$moment(contEndDate, "DD.MM.YYYY")
          );
        });

        if (!foundContract) return;

        this.allIdlePeriods.push({
          start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD"),
          end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD"),
        });
        foundContract.idlePeriods.push({
          transactions:
            this.allTransactions.filter(
              (transaction) =>
                this.$moment(transaction.start, "YYYY-MM-DD").isSameOrAfter(
                  this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD")
                ) &&
                this.$moment(transaction.end, "YYYY-MM-DD").isSameOrBefore(
                  this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD")
                )
            ) || [],
          start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format(
            "DD.MM.YYYY"
          ),
          end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format(
            "DD.MM.YYYY"
          ),
        });
        this.existingTimestops.push({
          start: this.$moment(timespan.TimeSpan.Start, "YYYY-MM-DD").format(
            "DD.MM.YYYY"
          ),
          end: this.$moment(timespan.TimeSpan.End, "YYYY-MM-DD").format(
            "DD.MM.YYYY"
          ),
        });
      });
      if (!contracts.length && newContract && newContract.title)
        contracts.push(newContract);

      if (!contracts.find((cont) => cont.active && !cont.future))
        contracts[contracts.length - 1].active = true;
      return contracts;
    },
    infoPageRules() {
      // Überprüfen, ob die Start- und Enddaten vorhanden und korrekt sind
      if (
        !this.timestopInfos.start ||
        !this.timestopInfos.end ||
        new Date(this.timestopInfos.start) > new Date(this.timestopInfos.end)
      ) {
        return true;
      }

      // Überprüfen, ob die restlichen Felder ausgefüllt sind
      if (
        !this.timestopInfos.duration ||
        !this.timestopInfos.durationSelection ||
        !this.timestopInfos.fee
      ) {
        return true;
      }

      // Wenn bis hierher alles in Ordnung ist, ist die Validierung erfolgreich
      return false;
    },
  },
  watch: {
    async visible() {
      if (this.visible) {
        this.e1 = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}
</style>